<template>
  <div class="login-container">
    <h3>Баталгаажуулах</h3>
    <el-alert
      title="Хэрэглэгчийн нэр эсвэл нууц үг буруу байна!"
      v-if="error"
      type="error"
    ></el-alert>
    <el-form v-on:submit.prevent="onSubmit" ref="form">
      <el-form-item prop="email" label="И-мэйл">
        <el-input v-model="username"></el-input>
      </el-form-item>
      <el-form-item prop="pass" label="Баталгаажуулах код">
        <el-input
          v-model="code"
          type="text"
          @keyup.enter.native="submit()"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          v-loading="load"
          :disabled="load"
          type="primary"
          @click="submit()"
          @keyup.enter="submit()"
          >Баталгаажуулах</el-button
        >
      </el-form-item>
      <p class="recover-pass">
        <a @click="resend">Код дахин илгээх</a>
      </p>
    </el-form>
  </div>
</template>
<script>
import { confirmSignUp, resendSignUp } from "@/utils/auth.js";
import { Message } from "element-ui";
export default {
  name: "SignUpConfirm",
  data() {
    return {
      load: false,
      error: false,
      valid: false,
      username: "",
      code: ""
    };
  },
  computed: {
    emailRules() {
      return [
        v => !!v || "E-mail is required",
        v => /.+@.+/.test(v) || "E-mail must be valid"
      ];
    },
    codeRules() {
      return [
        v => !!v || "Code is required",
        v => (v && v.length === 6) || "Code must be 6 digits"
      ];
    }
  },
  methods: {
    submit() {
      this.load = true;
      confirmSignUp(this.username, this.code).then(response => {
        this.load = false;
        if (!response) {
          Message({
            message: "Баталгаажуулалт буруу байна.",
            duration: 2500,
            type: "error",
            showClose: 1
          });
        }
      });
    },
    resend() {
      resendSignUp(this.username);
    }
  }
};
</script>
